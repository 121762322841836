export const environment = {
    production: false,
    env: 'qas',
    apiUrl: 'https://dms-dev-api.khmerbeverages.com',
    apiReportUrl: 'https://dms-dev-api.khmerbeverages.com',
    clientId: '987654321123456789',
    authorization: 'Bearer 1e48ed01e35b9b172315d218a79f83086772befe',
    firebasePath: '/qas/users/locations',
    firebase: {
        apiKey: 'AIzaSyDBuCAXPk0OUD_aIbehzHaqZpy41LsKee8',
        authDomain: 'cmg-dms-393202.firebaseapp.com',
        databaseURL: 'https://cmg-dms-393202-default-rtdb.asia-southeast1.firebasedatabase.app',
        projectId: 'cmg-dms-393202',
        storageBucket: 'cmg-dms-393202.appspot.com',
        messagingSenderId: '29438316490',
        appId: '1:29438316490:web:5febf3f4ed6b2031dcc933',
        measurementId: 'G-V23PLJX0T8'
    }
};
